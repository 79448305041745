import React, { useState, useEffect, useRef } from "react"
import i18n from "i18next"
import columnImageRight from "../images/transformation/transformation-banner.png"

// Layout
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"

import teamEs from "./../components/ourDNA/serviceOurTeam/teamEs"
import teamEn from "./../components/ourDNA/serviceOurTeam/teamEn"

import BannerTransformation from "../components/transformation/BannerTransformation"
import HowDoWeDoIt from "../components/transformation/HowDoWeDoIt"
import BenefitsOfAgile from "../components/transformation/BenefitsOfAgile"

const Transformation = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([])
  const [youtubeVideoId, setYoutubeVideoId] = useState("")
  const [team, setTeam] = useState(teamEs)
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  const attributesMainTitle = {
    id: "Transformation",
    title: i18n.t("pageourdna.ourteam"),
    styles: {
      bulletColor: "dark-blue",
      textColor: "light-blue",
      fontSize: "44",
      fontSizeMobile: "36",
      alignMobile: "left",
      alignDesktop: "left",
      marginL: "20",
    },
  }
  useEffect(() => {}, [i18n.language])

  return (
    <Layout>
      <SEO
        title={"Transformation | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />

      <BannerTransformation
        bannerImageURL={columnImageRight}
      ></BannerTransformation>

      <HowDoWeDoIt />

      <BenefitsOfAgile />
    </Layout>
  )
}

export default withNamespaces()(Transformation)
