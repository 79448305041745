import React, { Fragment, useState, useContext, useEffect } from "react"
import image1 from "../../images/transformation/whyUsTransformation.png"
import { LoadingContext } from "../context/LoadingContext"

import check from "../../images/products/check.png"
import i18n from "../i18n"

const BannerTransformation = ({ bannerImageURL }) => {
  const [dinamicHeight, setDinamicHeight] = useState(0)
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  const imagesArr = [
    {
      texto: i18n.t("BannerTransformation.check1"),
    },
    {
      texto: i18n.t("BannerTransformation.check2"),
    },
    {
      texto: i18n.t("BannerTransformation.check3"),
    },
  ]

  return (
    <Fragment>
      <div className="container-banner-main">
        <div className="shared-banner-container">
          {windowWidth >= 1025 ? (
            <div className="flex desktop:flex-row bannerPrincipal">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <br></br>
                    <br></br>
                    <br></br>
                    {i18n.language === "en" ? (
                      <>
                        <span className="textOurExperience2 flex">
                          <p className="subtitleTextTalent">E</p>
                          <p className="subtitleTextTalentX">x</p>
                          <p className="subtitleTextTalent">
                            ceptional products
                          </p>
                        </span>
                        <span className="textTalentATS">
                          <p className="colorTextExperience2">Agile</p>
                          {windowWidth >= 1024 ? (
                            <>
                              <p className="colorTextExperience2">
                                Transformation
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="colorTextExperience2">
                                Transformat
                              </p>
                              <p className="colorTextExperience2">ion</p>
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="textOurExperience2 flex">
                          <p className="subtitleTextTalent">Productos E</p>
                          <p className="subtitleTextTalentX">x</p>
                          <p className="subtitleTextTalent">cepcionales</p>
                        </span>
                        <span className="textTalentATS">
                          {windowWidth >= 1025 ? (
                            <>
                              <p className="colorTextExperience2">
                                Transformación
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="colorTextExperience2">Transforma</p>
                              <p className="colorTextExperience2">ción</p>
                            </>
                          )}
                          <p className="colorTextExperience2">Ágil</p>
                        </span>
                      </>
                    )}
                    <br></br>
                    <br></br>
                    <br></br>
                  </h3>
                </div>
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerAcademyContentStyle">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex desktop:flex-row bannerPrincipalMobile">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                <div className="flex flex-col txt-our-adn txt-our-adn-movil">
                  <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
                    <br></br>
                    <br></br>
                    {i18n.language === "en" ? (
                      <>
                        <span className="textOurExperience2 flex">
                          <p className="subtitleTextTalent">E</p>
                          <p className="subtitleTextTalentX">x</p>
                          <p className="subtitleTextTalent">
                            ceptional products
                          </p>
                        </span>
                        <span className="textTalentATS">
                          <p className="colorTextExperience2">Agile</p>
                          {windowWidth >= 1024 ? (
                            <>
                              <p className="colorTextExperience2">
                                Transformation
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="colorTextExperience2">
                                Transformat
                              </p>
                              <p className="colorTextExperience2">ion</p>
                            </>
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="textOurExperience2 flex">
                          <p className="subtitleTextTalent">Productos E</p>
                          <p className="subtitleTextTalentX">x</p>
                          <p className="subtitleTextTalent">cepcionales</p>
                        </span>
                        <span className="textTalentATS">
                          {windowWidth >= 1025 ? (
                            <>
                              <p className="colorTextExperience2">
                                Transformación
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="colorTextExperience2">Transforma</p>
                              <p className="colorTextExperience2">ción</p>
                            </>
                          )}
                          <p className="colorTextExperience2">Ágil</p>
                        </span>
                      </>
                    )}
                    <br></br>
                    <br></br>
                  </h3>
                </div>
              </div>
            </div>
          )}

          <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 ml-4">
            {windowWidth >= 1025 ? (
              <div className="flex w-full">
                <div className="contentWhyUs divInfoExsisAcademy bigdesktop:ml-40">
                  <div>
                    <p className="textTitleWhyUS">
                      {i18n.t("BannerTransformation.title1")}
                    </p>
                  </div>
                  <div className="eventRightTimeLineMobile">
                    <p className="titleInfoExsisAcademy">
                      {i18n.t("BannerTransformation.subtitle1")}
                    </p>
                  </div>

                  <p className="textWhyUsTransformation">
                    {i18n.t("BannerTransformation.text1")}
                  </p>

                  <div className="mt-8">
                    {imagesArr.map((item, i) => {
                      return (
                        <div className="flex ">
                          <div className="flex mb-4">
                            <button className="checkWhatIsAgile">
                              <img src={check} alt="...." className="m-auto" />
                            </button>
                            <p className="textCheckWhyUs">{item.texto}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="mt-10 divInfoExsisAcademy ">
                  {windowWidth >= 1025 && windowWidth <= 1700 ? (
                    <div className="">
                      <img
                        src={image1}
                        alt="...."
                        className="desktop:ml-12 mt-16"
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <img
                        src={image1}
                        alt="...."
                        className="desktop:ml-12 mt-16"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full">
                <div>
                  <p className="textTitleWhyUSMobile">
                    {i18n.t("BannerTransformation.title1")}
                  </p>
                </div>
                <div className="  mt-10 divInfoExsisAcademyMobile ">
                  <div className=" ">
                    <img src={image1} alt="...." className="" />
                  </div>
                </div>
                <div className="mt-2 divInfoExsisAcademyMobile">
                  <div className="eventRightTimeLineMobile">
                    <p className="titleInfoExsisAcademy">
                      {i18n.t("BannerTransformation.subtitle1")}
                    </p>
                  </div>

                  <p className="textInfoExsisAcademy2Mobile">
                    {i18n.t("BannerTransformation.text1")}
                  </p>

                  {imagesArr.map((item, i) => {
                    return (
                      <div className="flex mt-4 ">
                        <div className=" flex">
                          <button className="checkWhatIsAgile">
                            <img src={check} alt="...." className="m-auto" />
                          </button>
                          <p className="textCheckWhatisMobile">{item.texto}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

BannerTransformation.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerTransformation
