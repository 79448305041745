import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../components/context/LoadingContext"

import image1 from "../../images/transformation/benefits1.png"
import image2 from "../../images/transformation/benefits3.png"
import image3 from "../../images/transformation/benefits4.png"
import image4 from "../../images/transformation/benefits2.png"

const BenefitsOfAgile = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const ourBenefits = [
    {
      imagen: image1,
      texto: i18n.t("BenefitsOfAgile.card1"),
    },
    {
      imagen: image2,
      texto: i18n.t("BenefitsOfAgile.card2"),
    },
    {
      imagen: image3,
      texto: i18n.t("BenefitsOfAgile.card3"),
    },

    {
      imagen: image4,
      texto: i18n.t("BenefitsOfAgile.card4"),
    },
  ]

  return (
    <div className={`desktop:p-10 p-6 `}>
      {windowWidth >= 1025 ? (
        <>
          <div className="flex bigdesktop:ml-40">
            <div className="countTextCountMobile">
              <p className="textAboutUs">{i18n.t("BenefitsOfAgile.title")}</p>
              <p className="titleOurServices">
                {i18n.t("BenefitsOfAgile.subtitle")}
              </p>
            </div>
          </div>

          <div className="desktop:w-full bigdesktop:w-5/6 contentApply flex mt-10 mx-auto">
            {ourBenefits.map((item, i) => {
              return (
                <div className="mr-6 ">
                  <img src={item.imagen} alt="...." className="" />
                  <p className="textImagenesBenefits">{item.texto}</p>
                </div>
              )
            })}
          </div>

          <div>
            <p className="bigdesktop:ml-40 textFinallyTransformation">
              {i18n.t("BenefitsOfAgile.text")}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <div className="countTextCountMobile">
              <p className="subTitleOurServices">
                {i18n.t("BenefitsOfAgile.title")}
              </p>
              <p className="titleOurServices">
                {i18n.t("BenefitsOfAgile.subtitle")}
              </p>
            </div>
          </div>

          <div className="mt-10">
            {ourBenefits.map((item, i) => {
              return (
                <div className="mx-4 mt-4">
                  <img src={item.imagen} alt="...." className="" />
                  <p className="textImagenesBenefits">{item.texto}</p>
                </div>
              )
            })}
          </div>
          <div>
            <p className="textFinallyTransformationMobile">
              {i18n.t("BenefitsOfAgile.text")}
            </p>
          </div>
        </>
      )}

      <br />
    </div>
  )
}

export default BenefitsOfAgile
