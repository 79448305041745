import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../context/LoadingContext"
import image1 from "../../images/transformation/HowDoWeDoIt.png"
import image2 from "../../images/transformation/HowDoWeDoItEn.png"
import { Link } from "gatsby"

const HowDoWeDoIt = () => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <div className={`desktop:p-20 p-6 whyusdivOp`}>
      {windowWidth >= 1025 ? (
        <div className="flex w-full contentApply">
          <div className="contentWhyUs mx-12 bigdesktop:ml-32">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("HowDoWeDoItTransformation.title")}
              </p>
            </div>

            <p className="textInfoExsisAcademy2 my-8">
              {i18n.t("HowDoWeDoItTransformation.text1")}
            </p>

            <p className="textInfoExsisAcademy2 my-8">
              {i18n.t("HowDoWeDoItTransformation.text2")}
            </p>

            <Link to={"/contact-us"}>
              <button className="buttonLetsTalk flex justify-start items-center h-11 w-48">
                <span className="mx-auto">
                  {i18n.t("AboutThisProduct.button1")}
                </span>
              </button>
            </Link>
          </div>
          <div className="  mt-10 mx-12 ">
            <div className="">
              <img
                src={i18n.language === "en" ? image2 : image1}
                alt="...."
                className="mr-24"
                width={607}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className=" w-full">
          <div className="contentWhyUs divInfoExsisAcademyMobile">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("HowDoWeDoItTransformation.title")}
              </p>
            </div>

            <p className="textInfoExsisAcademy2Mobile mt-8">
              {i18n.t("HowDoWeDoItTransformation.text1")}
            </p>

            <p className="textInfoExsisAcademy2Mobile mt-8">
              {i18n.t("HowDoWeDoItTransformation.text2")}
            </p>
          </div>
          <div className="  mt-10 divInfoExsisAcademyBootcampsMobile">
            <div className="mt-4">
              <img
                src={i18n.language === "en" ? image2 : image1}
                alt="...."
                className=""
              />
            </div>
          </div>

          <Link to={"/contact-us"}>
            <button className="buttonLetsTalk flex justify-start items-center h-11 w-full">
              <span className="mx-auto">
                {i18n.t("AboutThisProduct.button1")}
              </span>
            </button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default HowDoWeDoIt
